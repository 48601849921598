import { LoadingButton } from '@mui/lab'
import { ButtonProps as MuiButtonProps } from '@mui/material'
import React from 'react'
import { palette } from '../../styles/theme'

interface LoadingButtonProps extends MuiButtonProps {
  children?: any
  kind?: 'primary' | 'secondary' | 'outlined' | 'text'
  fullWidth?: boolean
  loading?: boolean
  secondary?: boolean
  loadingPosition?: 'start' | 'end' | 'center'
}

export type ButtonCustomProps = LoadingButtonProps & MuiButtonProps

const Button: React.FC<React.PropsWithChildren<ButtonCustomProps>> = ({
  children,
  fullWidth,
  secondary,
  ...rest
}) => {
  const { color = 'primary', variant } = rest

  const hasBorder = (theme: any) =>
    secondary && color ? `1px solid ${theme.palette[color].main}` : ''

  const disabledColor = (theme: any) =>
    color !== 'inherit' && color
      ? theme.palette[color].main
      : palette.text.disabled

  return (
    <LoadingButton
      sx={{
        backfaceVisibility: 'hidden',
        WebkitBackfaceVisibility: 'hidden',
        boxShadow: '0px 2px 2px #0000001a',
        height: rest.size ? 'auto' : '48px',
        textAlign: 'center',
        textTransform: 'none',
        color: variant ? palette.primary.main : '#fff',
        lineHeight: '16px',
        px: '24px',
        py: '12px',
        border: hasBorder,
        '&:hover': {
          border: hasBorder,
          boxShadow: '0px 2px 2px #0000001a',
        },
        svg: {
          fill: secondary ? palette.text.primary : '#fff!important',
        },
        '&:disabled': () =>
          !secondary
            ? {
                backgroundColor: disabledColor,
                color: palette.primary.main,
                opacity: '0.3',
                border: hasBorder,
              }
            : {
                borderColor: disabledColor,
                color: disabledColor,
                opacity: '0.3',
                border: hasBorder,
              },
      }}
      fullWidth={fullWidth}
      variant="contained"
      type="button"
      {...rest}
    >
      {children}
    </LoadingButton>
  )
}

export default Button
